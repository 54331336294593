define("semantic-works/routes/test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TestRoute extends Ember.Route {}

  _exports.default = TestRoute;
});