define("semantic-works/templates/tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ng3O8Bx/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sw-container\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Sorry, nothing here yet. We're working on an improved version of the tutorial, but you can find a previous version at \"],[7,\"a\",true],[10,\"href\",\"https://mu.semte.ch/getting-started\"],[8],[0,\"https://mu.semte.ch/getting-started\"],[9],[0,\".\"],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "semantic-works/templates/tutorial.hbs"
    }
  });

  _exports.default = _default;
});