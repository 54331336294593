define("semantic-works/controllers/tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TutorialController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "codemirrorSettings", {
        lineNumbers: true,
        mode: 'javascript'
      });

      _defineProperty(this, "codemirrorValue", `'use strict';

const EmberApp = require('ember-cli/lib/broccoli/ember-app');

module.exports = function(defaults) {
  let app = new EmberApp(defaults, {
    codemirror: {
      modes: ['javascript']
    }
  });
  return app.toTree();
};`);
    }

  }

  _exports.default = TutorialController;
});