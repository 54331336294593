define("semantic-works/components/documentation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="pure-g">
      <div class="pure-u-1-5">
          <div class='sw-docs-menu'>
              <MenuItems @docTree={{@docTree}} @level={{0}} @clickHandler={{this.changeDoc}}/>
          </div>
      </div>
      <div class="pure-u-4-5">
          <div class='sw-docs-contents'>
              {{this.currentDoc}}
          </div>
      </div>
  </div>
  
  */
  {
    id: "T1vWH2Wt",
    block: "{\"symbols\":[\"@docTree\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"pure-g\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pure-u-1-5\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sw-docs-menu\"],[8],[0,\"\\n            \"],[5,\"menu-items\",[],[[\"@docTree\",\"@level\",\"@clickHandler\"],[[23,1,[]],0,[23,0,[\"changeDoc\"]]]]],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pure-u-4-5\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sw-docs-contents\"],[8],[0,\"\\n            \"],[1,[23,0,[\"currentDoc\"]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "semantic-works/components/documentation.hbs"
    }
  });

  let DocumentationComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class DocumentationComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentDoc", _descriptor, this);
    }

    changeDoc(url) {
      this.currentDoc = Ember.String.htmlSafe("<zero-md src='" + url + "'></zero-md>");
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentDoc", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.String.htmlSafe("<zero-md src='https://raw.githubusercontent.com/mu-semtech/mu-project/master/README.md'></zero-md>");
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeDoc", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeDoc"), _class.prototype)), _class));
  _exports.default = DocumentationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DocumentationComponent);
});