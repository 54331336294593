define("semantic-works/components/menu-items", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @docTree as |leaf|}}
    {{#if leaf.value}}
      {{#if leaf.link}}
        {{this.whitespace}}<button type="button" {{on "click" (fn @clickHandler leaf.value)}}>{{leaf.name}}</button><br>
      {{else}}
        {{this.whitespace}}{{leaf.name}}<br>
      {{/if}}
      <MenuItems @docTree={{leaf.value}} @level={{this.nextLevel}} @clickHandler={{@clickHandler}}/>
    {{/if}}
  {{/each}}
  */
  {
    id: "BvrSMl4W",
    block: "{\"symbols\":[\"leaf\",\"@clickHandler\",\"@docTree\"],\"statements\":[[4,\"each\",[[23,3,[]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"value\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"link\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,0,[\"whitespace\"]],false],[7,\"button\",false],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]],[23,1,[\"value\"]]],null]]],[8],[1,[23,1,[\"name\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,0,[\"whitespace\"]],false],[1,[23,1,[\"name\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[5,\"menu-items\",[],[[\"@docTree\",\"@level\",\"@clickHandler\"],[[23,1,[\"value\"]],[23,0,[\"nextLevel\"]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "semantic-works/components/menu-items.hbs"
    }
  });

  let MenuItemsComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class MenuItemsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "whitespace", _descriptor, this);

      _initializerDefineProperty(this, "nextLevel", _descriptor2, this);

      for (var i = 0; i < this.args.level; i++) {
        this.whitespace = Ember.String.htmlSafe(this.whitespace + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
      }
    }

    clickHandler(url) {}

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "whitespace", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "nextLevel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.level + 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clickHandler", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clickHandler"), _class.prototype)), _class));
  _exports.default = MenuItemsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MenuItemsComponent);
});